<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm">
      <el-form-item>
        <el-input
          v-model="dataForm.keyword"
          placeholder="输入关键字搜索"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-select v-model="dataForm.did" placeholder="选择部门" clearable>
          <el-option
            v-for="item in departmentOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="medium"
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        header-align="center"
        align="center"
        label="序号"
        type="index"
        width="70"
      >
      </el-table-column>
      <el-table-column
        label="ID"
        prop="id"
        align="center"
        width="190"
      ></el-table-column>
      <el-table-column
        prop="employee.name"
        header-align="center"
        align="center"
        label="部门负责人"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        header-align="center"
        align="center"
        label="部门名字"
        width="200"
      >
      </el-table-column>
      <el-table-column
        prop="profiles"
        header-align="center"
        align="center"
        label="部门简介"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="创建时间"
        width="120"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row.createDate ? scope.row.createDate.split(" ")[0] : "" }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            plain
            @click="addOrUpdateHandle(scope.row.id)"
            >修改</el-button
          >
          <el-button
            type="danger"
            size="mini"
            plain
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100, 1000, 10000]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="refreshDataListHandle"
    ></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from "./department-add-or-update";

export default {
  data() {
    return {
      dataForm: {
        keyword: "",
        did: "",
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,

      departmentOptions: [], // 部门选项
    };
  },
  components: {
    AddOrUpdate,
  },
  created() {
    this.getDataList();
    this.getDepartmentList();
  },
  methods: {
    // 重新获取数据
    refreshDataListHandle() {
      this.getDataList();
      this.getDepartmentList();
    },
    // 获取部门列表
    getDepartmentList() {
      this.$http.department
        .list({
          page: 1,
          limit: 999,
        })
        .then((res) => {
          this.departmentOptions = res?.data?.list || [];
        });
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;

      this.$http.department.list(this.dataForm).then((data) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalPage = data.data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      // 全局mixin混入事件；val输入的备注(then：确认按钮后的操作；catch：取消后的操作)
      this.remarksDelete()
        .then((val) => {
          this.$http.department
            .delete({
              id,
              remarks: val,
            })
            .then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: data.msg,
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.getDataList();
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
        })
        .catch(() => {});
    },
  },
};
</script>
